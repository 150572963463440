import Vue from 'vue';
import App from '../src/pages/login/login.vue';
import i18n from '../src/assets/i18n/i18n';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Lodash from 'lodash';
import '../src/assets/css/element-variables.scss';
import setPermission from "../src/assets/utils/directive";
Vue.use(Element)
Vue.config.productionTip = false;
new Vue({ i18n,render: h => h(App) }).$mount('#login'); 