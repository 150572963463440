<template lang="html">
    <el-dialog :title="title[type]" :visible="flag_normal" center :lock-scroll="false" @close="close" custom-class="login_dialog_class" width="740px" append-to-body modal-append-to-body>
      <div class="content">
        <div class="box">
          <p>
            <span>1.{{type=='add'?$t('login_00026'):$t('login_00024')}}</span>
            <span v-if="type=='reg'">{{$t('login_00029')}}</span>
          </p>
          <p>
            <span>2.{{type=='add'?$t('login_00027'):$t('login_00025')}}</span>
            <span v-if="type=='reg'">{{$t('login_00030')}}</span>
          </p>
          <p v-if="type=='reg'">
            <span>3.{{$t('login_00031')}}</span>
            <span>{{$t('login_00032')}}</span>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <p v-if="type=='add'" class="tips">{{$t('login_00028')}}</p>
        <el-button type="primary" @click="toReg">{{$t('login_00021')}}</el-button>
      </span>
    </el-dialog>
</template>

<script>
export default {
  props: ["flag_normal", "type", "product_area"],
  data() {
    return {
      title: {
        reg: this.$parent.$t("login_00022"),
        add: this.$parent.$t("login_00023"),
      },
    };
  },
  methods: {
    close() {
      this.$emit("cancel", false);
    },
    toReg() {
      let val;
      this.type == "add" ? (val = 2) : (val = 1);
      if (this.product_area == "eu") {
        window.location.href =
          "./registerInternational.html?selectedLevel=" + val;
      } else {
        window.location.href = "./register.html?selectedLevel=" + val;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.login_dialog_class {
  border-radius: 20px;
  .el-dialog__header {
    background: initial;
    padding: 20px !important;
  }
  .el-dialog__title {
    font-size: 32px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #171717;
    line-height: 44px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 80px;
    p {
      font-size: 16px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #171717;
      line-height: 30px;
      margin-bottom: 10px;
      max-width: 450px;
      > span:last-child {
        padding-left: 15px;
        display: block;
      }
    }
  }
  .tips {
    font-size: 16px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #171717;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
</style>

